import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private timeout: any;
  readonly URLs_TO_INTERCEPT = [
    environment.mkgoURL,
    environment.migrate.URL
  ]

  /** The idToken observable streams events triggered by sign-in, sign-out and token refresh events. */
  idToken$: Observable<string>;
  
  constructor(
    private auth: AngularFireAuth,
    private dialog: MatDialog
  ) { 
    this.idToken$ = auth.idToken;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(
      () => {
        this.dialog.closeAll();
        this.auth.signOut();
      },
      6 * 60 * 60 * 1000
    );
    if (this.URLs_TO_INTERCEPT.some(url => request.url.startsWith(url))) {
      return this.idToken$.pipe(
        take(1),
        mergeMap((token: string) => {
          if (token) {
            request = request.clone({
              setHeaders: { Authorization: `Bearer ${token}` }
            });
          }
          return next.handle(request);
        })
      );
    }
    return next.handle(request);
  }

}
