import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectUtilities } from 'src/app/class/select-utilities';
import { UnsubscribeAll } from 'src/app/class/unsubscribe-all';
import { Utilities } from 'src/app/class/utilities';
import { ConfirmationDialogComponent } from 'src/app/component/dialog/confirmation-dialog/confirmation-dialog.component';
import { PhotoEditComponent } from 'src/app/component/dialog/photo-edit/photo-edit.component';
import { Company } from 'src/app/interface/company';
import { State } from 'src/app/interface/state';
import { AddressService } from 'src/app/service/address.service';
import { CompanyService } from 'src/app/service/company.service';
import { LayoutService } from 'src/app/service/layout.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { companyBrands } from 'src/app/shared/lists/company-brands';
import { COMPANY_TAB, companyTabs } from 'src/app/shared/lists/company-tabs';
import { NATUREZAS_DE_OPERACAO } from 'src/app/shared/lists/naturezas-de-operacao';
import { NFSE_PATTERNS } from 'src/app/shared/lists/nfse-patterns';
import { REGIM_ESPEC_TRIBUT_PREST } from 'src/app/shared/lists/reg_esp_trib_pres';
import { DocumentPipe } from 'src/app/shared/pipes/document.pipe';
import { environment } from 'src/environments/environment';

const SERIE_RGX = /^[1-9]{1}[0-9]{0,2}$|^0{1}$/;
const NFSE_SERIE_RGX = /^\w{1,5}$/;

const MekanicForm = () => {
  const form = new FormBuilder().group({
    id: [''],
    socialName: ['', [Validators.required, Validators.minLength(2)]],
    fancyName: ['', [Validators.required]],
    cnpj: ['', [Validators.required, Utilities.validateCNPJ()]],
    cnpjMatrix: ['', [Utilities.validateCNPJ()]],
    crt: ['', [Validators.required, Validators.min(1), Validators.max(4)]],
    address: new FormBuilder().group({
      cep: ['', [Validators.minLength(8), Validators.required]],
      street: ['', [Validators.minLength(2), Validators.maxLength(60), Validators.required]],
      number: ['', [Validators.required]],
      complement: [''],
      neighborhood: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(60)]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      type: ['', [Validators.required]]
    }),
    stateRegistration: ['', [Validators.required]],
    IM: ['', [Validators.required]],
    tributMunicipio: [''],
    regEspTrib: [''],
    verificaRPS: [''],
    exigibilidade: [''],
    munIncidISS: ['Não informa'],
    ISS: 0,
    numberInvoice: ['', [Validators.required, Validators.maxLength(9)]],
    numberInvoiceNFC: ['', [Validators.required, Validators.maxLength(9)]],
    numberInvoiceNFS: ['', [Validators.required, Validators.maxLength(15)]],
    numberGerencial: ['', [Validators.required, Validators.maxLength(9)]],
    serieInvoice: ['', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(3),
      Validators.pattern(SERIE_RGX),
      // Utilities.differentValues(['serieInvoiceNFC', 'serieInvoiceNFS', 'serieGerencial'])
    ]],
    serieInvoiceNFC: ['', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(3),
      Validators.pattern(SERIE_RGX),
      // Utilities.differentValues(['serieInvoice', 'serieInvoiceNFS', 'serieGerencial'])
    ]],
    serieInvoiceNFS: ['', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(5),
      Validators.pattern(NFSE_SERIE_RGX),
      // Utilities.differentValues(['serieInvoice', 'serieInvoiceNFC', 'serieGerencial'])
    ]],
    serieGerencial: ['', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(3),
      Validators.pattern(SERIE_RGX),
      // Utilities.differentValues(['serieInvoice', 'serieInvoiceNFC', 'serieInvoiceNFS'])
    ]],
    cnaeCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
    lastNSU: [''],
    accessKeyInvoice: [''],
    site: [''],
    phone: [''],
    phone2: [''],
    email: ['', [Validators.email]],
    companyLogo: [''],
    associateLogo: [''],
    companyLogoInput: [''],
    associationLogoInput: [''],
    migrateIntegrated: [''],
    controlStock: [''],
    openOS: [true],
    discountControl: [false],
    complementaryOS: [true],
    sendCode: [false],
    osCount: [true],
    patternNFSE: [''],
    makena: [false],
    tokenWhats: [''],
    authorized: [[]]
  }, {
    validator: Utilities.distinctControls('cnpj', 'cnpjMatrix') // both CNPJ should be distinct
  });
  return form;
}

const MakenaForm = () => {
  const form = new FormBuilder().group({
    id: [''],
    socialName: ['', [Validators.required, Validators.minLength(2)]],
    fancyName: ['', [Validators.required]],
    cnpj: ['', [Validators.required, Utilities.validateCNPJ()]],
    cnpjMatrix: ['', [Utilities.validateCNPJ()]],
    address: new FormBuilder().group({
      cep: ['', [Validators.minLength(8)]],
      street: ['', [Validators.minLength(2), Validators.maxLength(60)]],
      number: [''],
      complement: [''],
      neighborhood: ['', [Validators.minLength(2), Validators.maxLength(60)]],
      type: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
    }),
    site: [''],
    phone: ['', [Validators.required]],
    phone2: [''],
    email: ['', [Validators.email]],
    companyLogo: [''],
    associateLogo: [''],
    companyLogoInput: [''],
    associationLogoInput: [''],
    brand: ['', Validators.required],
    tab: [[COMPANY_TAB.Checklist, COMPANY_TAB.Cardápio, COMPANY_TAB.Pesquisa]],
    makena: [true],
    migrateIntegrated: [false],
    controlStock: [false],
    tokenWhats: [''],

  }, {
    validator: Utilities.distinctControls('cnpj', 'cnpjMatrix') // both CNPJ should be distinct
  });
  return form;
}

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrl: './company-detail.component.scss'
})
export class CompanyDetailComponent extends UnsubscribeAll implements OnInit, OnDestroy {
  SelectUtilities = SelectUtilities;
  form: FormGroup;
  states: State[] = [];
  openDialog: MatDialogRef<ConfirmationDialogComponent> | MatDialogRef<PhotoEditComponent>;
  companyLogoBlob: Blob;
  associationLogoBlob: Blob;
  companyBrands = companyBrands;
  companyTabs = companyTabs;
  COMPANY_TAB = COMPANY_TAB;
  regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
  NFSE_PATTERNS = NFSE_PATTERNS.sort((pt1, pt2) => pt1.localeCompare(pt2));
  NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => {
    return [1, 2, 3, 4, 10, 11].includes(nat.code) // default values
  });
  isMakena = environment.makena;
  companyData: Company;
  authorizatedCpfAndCpnjInput = new FormControl("");

  constructor(
    private _companyService: CompanyService,
    private _addressService: AddressService,
    private _layout: LayoutService,
    private _snackbar: SnackbarService,
    private _dialog: MatDialog,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.form = environment.makena ? MakenaForm() : MekanicForm();

    // clear city on change state
    this.subs.push(this.form.get('address.state').valueChanges
      .subscribe((value: string) => {
        this.form.get('address.city').setValue('');
      }));

    // find address on input cep
    this.subs.push(this.form.get('address.cep').valueChanges
      .subscribe(async (value: string) => {
        const cep = Utilities.removeMask(value);
        if (cep.length === 8) {
          try {
            const address = await this._addressService.searchCep(cep);
            this.form.get('address.number').setValue('');
            this.form.get('address.complement').setValue('');
            this.form.get('address.street').setValue(address.street);
            this.form.get('address.neighborhood').setValue(address.neighborhood);
            this.form.get('address.state').setValue(address.state);
            this.form.get('address.city').setValue(address.city);
          } catch (error) {
            console.error(error)
          }
        }
      }));

    if (!environment.makena) {
      // Update required fields for company integrated with Migrate
      this.subs.push(this.form.get('migrateIntegrated').valueChanges
        .subscribe((value: boolean) => {
          if (value) {
            this.form.get("lastNSU").setValidators([Validators.required]);
            this.form.get("accessKeyInvoice").setValidators([Validators.required]);
          } else {
            this.form.get("lastNSU").setValidators(null);
            this.form.get("accessKeyInvoice").setValidators(null);
          }
        }));

      // the <tributMunicipio> tag is required for some NFSE patterns
      this.subs.push(this.form.get('patternNFSE').valueChanges
        .subscribe((value: Company['patternNFSE']) => {

          switch (value) {
            case "Governo Digital":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [3, 4, 5, 6, 7, 8, 9].includes(nat.code));
              break;
            case "Embras Siap.Net":
            case "Síntese Tecnologia":
            case "Futurize":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST.slice(1, 7);
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [7, 8, 3, 9, 4, 5, 6].includes(nat.code));
              break;
            case "SIGCORP ABRASF":
            case "SMARAPDSil WS2":
            case "HM2 Soluções":
            case "WEB ISS 2.0":
            case "TIPLAN 2.0":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [7, 8, 3, 9, 4, 5, 6].includes(nat.code));
              break;
            case "IPM":
            case "IPM (Araranguá)":
            case "IPM (Brusque)":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [1, 2, 3, 4, 10, 11].includes(nat.code));
              break;
            
            case "Assessor Público":
                this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
                this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [12, 14, 16, 17, 1].includes(nat.code));
                break;
            case "Assessor Público (Lins-SP)":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [1, 3, 4, 8, 12, 14, 16, 17, 42].includes(nat.code));
              break;
            case "Assessor Público (Votorantim-SP)":
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [3, 4, 7, 10, 12, 14, 17].includes(nat.code));
              break;
            default:
              this.regEspTribList = REGIM_ESPEC_TRIBUT_PREST;
              this.NATUREZAS = NATUREZAS_DE_OPERACAO.filter(nat => [1, 2, 3, 4, 10].includes(nat.code));
              break;
          }

          if(value === "Futurize"){
            // CNAE is optional for some majors with pattern Futurize
            this.form.get('CNAE').removeValidators(Validators.required);
            this.form.get('CNAE').updateValueAndValidity();
          } else {
            this.form.get('CNAE').addValidators(Validators.required);
            this.form.get('CNAE').updateValueAndValidity();
          }

          if (value === 'IPM (Araranguá)') {
            this.form.get('tributMunicipio').setValidators(null);
            this.form.get('tributMunicipio').updateValueAndValidity();
          } else {
            this.form.get('tributMunicipio').setValidators([Validators.required]);
            this.form.get('tributMunicipio').updateValueAndValidity();
          }
        }));
    }

    this.getData();
  }

  ngOnDestroy() {
    this.companyLogoBlob = null;
    this.associationLogoBlob = null;
    this.unsubscribeAll();
  }

  private async getData() {
    this._layout.loader = true;
    try {
      [this.states] = await Promise.all([
        this._addressService.getStates()
      ]);

      const companyId = this._activatedRoute.snapshot.params['company'];
      
      if (companyId) {
        const company = await this._companyService.get(companyId, true);
        this.companyData = company;

        this.form.patchValue(company, { emitEvent: false });
        this.form.get('makena').setValue(environment.makena);
        if (environment.makena) {
          this.form.get('migrateIntegrated').setValue(false);
          this.form.get('controlStock').setValue(false);
        }
        this.form.get('openOS').setValue((company.openOS == 1 || company.openOS == null) ? true : false)
        if (company.complementaryOS == 2 || !company.complementaryOS) {
          this.form.get('complementaryOS').setValue(true);
        } else {
          this.form.get('complementaryOS').setValue(false);
        }
        if (company.discountControl == 2) {
          this.form.get('discountControl').setValue(true);
        } else {
          this.form.get('discountControl').setValue(false);
        }
        if (company.osCount == 2) {
          this.form.get('osCount').setValue(true);
        } else {
          this.form.get('osCount').setValue(false);
        }
        if (company.sendCode == 2) {
          this.form.get('sendCode').setValue(true);
        } else {
          this.form.get('sendCode').setValue(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this._layout.loader = false;
      // console.log(this.form.get('osCount'))
    }
  }

  public onInputCNPJ() {
    const cnpjInput = this.form.get('cnpj');
    const validStr = cnpjInput.value.replace(/\D/g, '');
    cnpjInput.setValue(validStr);
  }

  async onInputCep() {
    const cep = Utilities.removeMask(this.form.value.address.cep);

    if (cep.length === 8) {
      try {
        const address = await this._addressService.searchCep(cep);
        this.form.get('address.number').setValue('');
        this.form.get('address.complement').setValue('');
        this.form.get('address.street').setValue(address.street);
        this.form.get('address.neighborhood').setValue(address.neighborhood);
        this.form.get('address.state').setValue(address.state);
        this.form.get('address.city').setValue(address.city);
      } catch (error) {
        console.error(error)
      }
    }
  }

  onChangeState() {
    this.form.get('address.city').setValue('');
  }

  onClick(element: HTMLInputElement) {
    element.click();
  }

  async onChangeCompanyLogo(event: Event) {
    const files = (event.target as any).files as FileList;
    if (!files.length) {
      return;
    }
    const file = files[0];
    if (!Utilities.isImage(file)) {
      return;
    }
    this.openDialog = this._dialog
      .open(PhotoEditComponent, { disableClose: true, data: { file: file, ratio: 3 } });
    const canvas: HTMLCanvasElement = await this.openDialog.afterClosed().toPromise();
    this.openDialog = null;
    if (!canvas) {
      return;
    }
    this.companyLogoBlob = await Utilities.toBlob(canvas);
    const oldCanvas = document.getElementById('company-logo-canvas') as HTMLCanvasElement;
    canvas.id = oldCanvas.id;
    canvas.classList.add('photo');
    oldCanvas.replaceWith(canvas);
  }

  public async onChangeAssociationLogo(event: Event) {
    const files = (event.target as any).files as FileList;
    if (!files.length) {
      return;
    }
    const file = files[0];
    if (!Utilities.isImage(file)) {
      return;
    }
    this.openDialog = this._dialog
      .open(PhotoEditComponent, { disableClose: true, data: { file: file, ratio: 3 } });
    const canvas: HTMLCanvasElement = await this.openDialog.afterClosed().toPromise();
    this.openDialog = null;
    if (!canvas) {
      return;
    }
    this.associationLogoBlob = await Utilities.toBlob(canvas);
    const oldCanvas = document.getElementById('association-logo-canvas') as HTMLCanvasElement;
    canvas.id = oldCanvas.id;
    canvas.onclick = oldCanvas.onclick;
    canvas.classList.add('photo');
    oldCanvas.replaceWith(canvas);
  }

  public async deleteCompanyLogo() {
    this.openDialog = this._dialog
      .open(ConfirmationDialogComponent, { disableClose: true, data: { text: 'DELETE_IMAGE' } });
    const result: boolean = await this.openDialog.afterClosed().toPromise();
    this.openDialog = null;
    if (result) {
      document.getElementById('company-logo-canvas').classList.add('hidden');
      this.form.get('companyLogo').setValue('');
      this.companyLogoBlob = null;
    }
  }

  public async deleteAssociationLogo() {
    this.openDialog = this._dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: { text: 'DELETE_IMAGE' }
    });
    const result: boolean = await this.openDialog.afterClosed().toPromise();
    this.openDialog = null;
    if (result) {
      document.getElementById('association-logo-canvas').classList.add('hidden');
      this.form.get('associateLogo').setValue('');
      this.associationLogoBlob = null;
    }
  }

  public async onSubmit() {
    const company: Company = this.form.getRawValue();

    if (this.form.controls['complementaryOS'].value == true) {
      company.complementaryOS = 2
    } else {
      company.complementaryOS = 1
    }
    if (this.form.controls['osCount'].value == true) {
      company.osCount = 2
    } else {
      company.osCount = 1
    }
    if (this.form.controls['sendCode'].value == true) {
      company.sendCode = 2
    } else {
      company.sendCode = 1
    }
    company.discountControl = this.form.controls['discountControl'].value ? 2 : 1;
    company.openOS = this.form.controls['openOS'].value ? 1 : 2
    const param = { cnpj: company.cnpj };
    const isUpdate = Boolean(company.id);

    company.cnpj = this.addCnpjMask(company.cnpj);

    try {
      this._layout.loader = true;
      company.companyLogoBlob = this.companyLogoBlob;
      company.associationLogoBlob = this.associationLogoBlob;
      if (isUpdate) {
        await this._companyService.update(company)
        // console.log('ONSUBMITi:', JSON.stringify(company));
      } else {
        await this._companyService.add(company);
      }
      this.goBack()
      this._snackbar.success(isUpdate ? 'COMPANY.UPDATED' : 'COMPANY.ADDED', param);
    } catch (error) {
      this._snackbar.error(isUpdate ? 'COMPANY.NOT_UPDATED' : 'COMPANY.NOT_ADDED', param, error);
    } finally {
      this._layout.loader = false;
    }
  }

  addCnpjMask(cnpj: string) {
    return new DocumentPipe().transform(cnpj);
  }
  
  goBack() {
    this._router.navigate(["company"])
  }

  addNewCpfOrCnpj(value: string) {
    value = value.replaceAll(/\D/g, "");

    if (this.validateCpfCnpjValue(value, 11)) {
      this.addAuthorizedItem(value);
    } else if (this.validateCpfCnpjValue(value, 14)) {
      this.addAuthorizedItem(value);
    } else {
      this._snackbar.error("COMPANY.INVALID_DOCUMENT_ADD");
    }
  }

  addAuthorizedItem(value: string) {
    const authorizedPreviousValue = this.form.get('authorized').value;
    if (authorizedPreviousValue.length === 10) {
      this._snackbar.error("COMPANY.MAX_REGISTERS_SIZE")
      return;
    }
    this.form.get('authorized').setValue([...authorizedPreviousValue, value]);
    this.form.markAsDirty();
  }

  removeAuthorizedItem(index: number) {
    const authorizedPreviousValue = this.form.get('authorized').value;

    (authorizedPreviousValue as []).splice(index, 1);

    this.form.get('authorized').setValue(authorizedPreviousValue)
    this.form.markAsDirty();
  }

  toggleEdit(htmlElement: HTMLDivElement, cancel = false) {
    if (cancel) {
      htmlElement.classList.add("d-none");
      htmlElement.classList.remove("d-flex");
      return;
    }
    
    if (htmlElement.classList.contains("d-none")) {
      htmlElement.classList.remove("d-none");
      htmlElement.classList.add("d-flex");
    } else if (htmlElement.classList.contains("d-flex")) {
      htmlElement.classList.add("d-none");
      htmlElement.classList.remove("d-flex");
    }
  }
  
  editCpfOrCnpj(index: number, { value, htmlElement  }: { value: string, htmlElement: HTMLDivElement }) {
    value = value.replaceAll(/\D/g, "");
    const authorizedPreviousValue = this.form.get('authorized').value;

    if (this.validateCpfCnpjValue(value, 11)) {
      authorizedPreviousValue[index] = value;
      htmlElement.classList.add("d-none");
      htmlElement.classList.remove("d-flex");
    } else if (this.validateCpfCnpjValue(value, 14)) {
      authorizedPreviousValue[index] = value;
      htmlElement.classList.add("d-none");
      htmlElement.classList.remove("d-flex");
    } else {
      this._snackbar.error("COMPANY.INVALID_DOCUMENT_UPDATE");
    }
  }

  validateCpfCnpjValue(value: string, length: number): boolean {
    return value.length === length && !value.match(/[a-zA-Z!@#$%&*()\/\+]/g)
  }
}